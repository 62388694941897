import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import { json, LinksFunction, LoaderArgsWithContext } from "@remix-run/node";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation,
  useRouteError
} from "@remix-run/react";
import stylesheet from "~/tailwind.css?url";
import { Link } from "~/components/catalyst/link";
import logger from "~/logger";
import posthog from "posthog-js";
import { useEffect, useState } from "react";
import invariant from "tiny-invariant";
import TagManager from "react-gtm-module";
import { GoogleTagManagerConfig } from "~/GoogleTagManagerConfig";

export async function loader({ request, context }: LoaderArgsWithContext) {
  invariant(!!context.env.POST_HOG_API_TOKEN, "POST_HOG_API_TOKEN env var must be set but was not");
  invariant(!!context.env.META_PIXEL_ID, "META_PIXEL_ID env var must be set but was not");

  return json({
    postHogApiToken: context.env.POST_HOG_API_TOKEN!,
    gtmConfig: context.gtmConfig,
    metaPixelId: process.env.META_PIXEL_ID!,
    umamiWebsiteId: process.env.UMAMI_WEBSITE_ID || null
  });
}

export const links: LinksFunction = () => [
  { rel: "stylesheet", href: stylesheet },
];

function initGoogleTagManager(config: GoogleTagManagerConfig) {
  if (!config.tagId) {
    logger.info("skipping GTM initialization; no gtm id provided");
    return;
  }

  const tagManagerArgs = {
    gtmId: config.tagId,
    auth: config.auth,
    preview: config.env
  };
  const scriptElement = document.querySelector(`script[src^="https://www.googletagmanager.com/gtm.js?id=${config.tagId}"]`);

  if (!scriptElement) {
    TagManager.initialize(tagManagerArgs);
  }
}

function App() {
  const loaderData = useLoaderData<typeof loader>();
  const [tagManagerInitialized, setTagManagerInitialized] = useState(false);

  useEffect(() => {
    initFacebookPixel(loaderData.metaPixelId);
    initPosthogAnalytics(loaderData.postHogApiToken);
    if (!tagManagerInitialized) {
      initGoogleTagManager(loaderData.gtmConfig);
      setTagManagerInitialized(true);
    }
  }, []);

  const location = useLocation();
  useEffect(() => {
    posthog.capture("$pageview");
  }, [location]);


  return (
    <html lang="en" className="h-full bg-white">
    <head>
      {loaderData.umamiWebsiteId ? <script defer src="https://cloud.umami.is/script.js"
                                           data-website-id={loaderData.umamiWebsiteId}></script> : null}
      <script async src="https://cdn.tolt.io/tolt.js" data-tolt="836e43ab-f7a9-4022-b7d4-c333786f04d4"></script>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width,initial-scale=1" />
      <title>WellCheck</title>
      <Meta />
      <Links />
    </head>
    <body className="min-h-full">
    <Outlet />
    <ScrollRestoration />
    <Scripts />
    </body>
    </html>
  );
}

export default withSentry(App);

export function ErrorBoundary() {
  const error = useRouteError();
  logger.error(error, `**Root error boundary hit**`, );
  captureRemixErrorBoundaryError(error);

  return (
    <html lang="en" className="h-full">
    <head>
      <title>WellCheck - Error</title>
      <Meta />
      <Links />
    </head>
    <body className="h-full">
    <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <p className="text-base font-semibold text-indigo-600">Uh oh</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Something Went Wrong</h1>
        <p className="mt-6 text-base leading-7 text-gray-600">If you continue to have issues, please contact
          support.</p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <Link
            href="/"
            className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Go back home
          </Link>
        </div>
      </div>
    </main>
    <Scripts />
    </body>
    </html>
  );
}

async function initFacebookPixel(metaPixelId: string) {
  const { default: reactPixel } = await import("react-facebook-pixel"); // Adjust the import if needed
  const options = {
    autoConfig: true,
    debug: false // enable logs
  };

  reactPixel.init(metaPixelId, undefined, options);
  reactPixel.pageView();
}

function initPosthogAnalytics(apiToken: string) {
  if (apiToken.length == 0) {
    logger.info("skipping initializing posthog; apiToken was blank");
    return;
  }
  posthog.init(apiToken, {
    api_host: "https://us.i.posthog.com",
    person_profiles: "always"
  });
}
